<header class="d-flex flex-wrap justify-content-center mb-4 header-bg fixed-top ">
    <a href="https://www.trb.bank" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none my-auto ms-3">
      <img class="navbar-brand" src="../../../assets/images/logo.svg">
    </a>
    <div class="nav nav-pills my-auto mx-3">
      <a mat-raised-button color="accent" [routerLink]="['/login']">Log In</a>

    </div>
  </header>
  
    