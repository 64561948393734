export class Exception implements Error {
	constructor(message: string, sourceComponent: string) {
		this.error = new Error(message);
		this.sourceComponent = sourceComponent;
	}
	name!: string;
	message!: string;
	stack?: string | undefined;
	zoneAwareStack?: string | undefined;
	originalStack?: string | undefined;
	sourceComponent?: string | undefined;
	error!: Error;
}
