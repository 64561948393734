import { SuccessResponse } from '../../../../goldstar-share/src/app/models/models';
import { UserInfoType } from './interfaces';
import { EntityGetRequest } from '../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models/entity-get-request';
import { EntityUpdateRequest } from '../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models/entity-update-request';
import { GridsEditMode } from 'devextreme/common/grids';
import { EmployeeAnniversaryItem, EmployeeBirthdayItem } from '../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';

export interface Result<T> {
	isSuccess: boolean;
	message?: string;
	data?: T;
}

export interface AuditColumns {
	modUserSessionGUID?: string;
	modDateTime?: Date;
	modURL?: string;
	modAPI?: string;
}

export interface LookupColumns extends AuditColumns {
	label?: string;
	shortLabel?: string;
}

export interface User extends AuditColumns {
	userGUID?: string;
	userID?: string;
	name?: string;
	title?: string;
	branchGUID?: string;
}

export interface CommunityOrganization extends AuditColumns {
	communityOrganizationGUID?: string;
	label?: string;
}

export interface PurposeOfServiceLU extends LookupColumns {
	purposeOfServiceLUGUID?: string;
}

export interface PositionTypeLU extends LookupColumns {
	positionTypeGUID?: string;
}

export interface ProfitCenterLevelLU extends LookupColumns {
	profitCenterLevelLUGUID?: string;
}

export interface CommunityResponsibilityAndInvolvementLog extends AuditColumns {
	communityResponsibilityAndInvolvementLogGUID?: string;
	communityResponsibilityAndInvolvementLogID?: string;
	userGUID?: string;
	communityOrganizationGUID?: string;
	purposeOfServiceLUGUID?: string;
	positionTypeLUGUID?: string;
	officeHeld?: string;
	startDateTime?: Date;
	endDateTime?: Date;
}

export interface Branch extends AuditColumns {
	branchGUID?: string;
	branchID?: string;
	label?: string;
	address1?: string;
	address2?: string;
	city?: string;
	county?: string;
	state?: string;
	zip?: string;
	latitude?: number;
	longitude?: number;
	regionGUID?: string;
}

// export interface TypedRequestBody<T> extends Express.Request {
//     body: T,
// }

export enum FormOperationMode {
	Save = 'Save',
	Cancel = 'Cancel',
}

export interface InternalEnvironment {
	production: boolean;
	apiEnvironment: string;
	apiRoot: string;
	nodeApiRoot: string;
	clientApiRoot: string;
	nodeReportingApiRoot: string;

	azureAuthClientId: string;
	azureAuthority: string;
	azureRedirectUri: string;

	/* this holds the bucket which would be used by the internal web app to store files  */
	fileStorageBucket: string;

	webSocketApiUri: string;
}

export class GridModel<T> {
	key!: string;
	identifier?: string;
	columns!: GridColumnModel[];
	showCustomColumn?: boolean;
	rowEdit?: GridRowEditModel;
	allowViewing?: boolean;
	enableColumnChooser!: boolean;
	allowColumnReordering!: boolean;
	allowColumnResizing!: boolean;
	columnAutoWidth!: boolean;
	hoverStateEnabled!: boolean;
	showBorders!: boolean;
	allowRowFilter!: boolean;
	rowSelectionMode!: string;
	columnSortingMode!: string;
	pageSize!: number;
	showDetailedView?: boolean = false;
	defaultColumnFilterValue?: boolean = true;
	gridDataViewModel?: GridDataViewModel<T>;
	gridDataModel!: GridDataModel<T>;
	gridDataExport?: GridDataExportModel;
	enableFiles?: boolean;
	exportingRateWidget?: boolean;
	exportingFolder?: boolean;
	enableDocuments?: boolean;
	enableDragDrop?: boolean;
	gridName?: string;
	scrollMode?: GridScrollMode = GridScrollMode.Static;
	showClearFilter?: boolean = true;
	showSetting?: boolean = true;
	showExport?: boolean = true;
	showToolbar?: boolean = true;
	addButtonTitle?: string = 'Add';
	showSummary?: boolean;
	openEditOnRowClick?: boolean;
	showCustomHeaderFilter?: boolean;
	allowColumnGrouping?: boolean;
	deleteConfirmationMessage?: string;
}

export interface DataGridStorageModel {
	gridType?: DataGridType;
	gridColumnData?: GridColumnModel[];
	allowedPageSizes?: number[];
	filterValue?: any;
	pageIndex?: number;
	pageSize?: number;
	searchText?: string;
	filterOption?: string;
	totalLoadedRecords?: number;
	hasActiveFilter?: any;
	customGridFilters?: DataGridCustomFilters;
}
export interface DataGridCustomFilters {
	gridCheckboxFilter?: GridFilterValuePair[];
	gridSearch?: GridFilterValuePair | null;
	gridDateFilter?: GridDateFilterValue | null;
	gridDropdownFilter?: GridFilterValuePair | null;
	gridMultiSelectDropdownFilter?: GridFilterValuePair[] | null;
}

export interface DataGridType {
	gridName?: string;
	gridCheckboxFilter?: GridFilterValuePair[];
	gridSearch?: GridFilterValuePair | null;
	gridDateFilter?: GridDateFilterValue | null;
	gridDropdownFilter?: GridFilterValuePair | null;
	filterOption?: string | null;
	totalLoadedRecords?: number | null;
	hasActiveFilter?: boolean | null;
}

export interface GridFilterValuePair {
	filterName: string;
	filterValue: string | boolean | any[];
}

export interface GridDateFilterValue {
	fromDate: string | null;
	toDate: string | null;
}

export enum GridScrollMode {
	Virtual = 'Virtual',
	Static = 'Static',
	Paged = 'Paged',
}

export interface GridRowEditModel {
	mode: GridsEditMode;
	allowRowUpdating: boolean;
	allowRowDeleting: boolean;
	allowInlineAdd: boolean;
	gridPopupModel?: GridPopupModel;
	isEditMode?: boolean;
}

export interface GridColumnSummaryModel {
	summaryType: string;
	displayFormat?: string;
	name?: string;
	valueFormat?: string;
}

export interface GridPopupModel {
	datafield: GridPopupFieldModel[];
}

export interface GridPopupFieldModel {
	name: string;
	dataType: string;
}

export interface GridColumnModel {
	label: string;
	dataField: string;
	datatype: string;
	width?: number | string;
	isDropDown?: boolean;
	isVisible: boolean;
	allowFiltering: boolean;
	allowEditing: boolean;
	allowColumnFixing: boolean;
	showInColumnChooser: boolean;
	identifier?: string;
	isKey: boolean;
	cssClass?: string;
	sortIndex?: string;
	sortOrder?: string;
	selectedFilterOperation?: string;
	filterValue?: string;
	isCustomTemplate?: boolean;
	routeToEditHyperlink?: boolean;
	cellBackground?: boolean;
	headerBackground?: boolean;
	getCellBackground?(data: any): any;
	getHeaderBackground?(data: any): any;
	rowBackground?: boolean;
	getRowBackground?(data: any): any;
	getColumnTemplate?(data: any): any;
	dropDownSource: LookupSourceModel[];
	visibleIndex?: number;
	visible?: boolean;
	cellTemplate?: string;
	format?: string;
	columnSummary?: GridColumnSummaryModel;
	showHeaderFilter?: boolean;
	filterValues?: any[];
	hasCustomEditPermission?: boolean;
	groupIndex?: number | null;
	filterOperations?: any[];
	allowSorting?: boolean;
}

export interface GridRowFilterModel {
	filterValue?: any;
	operationKey?: string;
}

export interface GridDataViewModel<T> {
	onView(data: T): void;
	onReadOnlyView?(data: T): void;
}

export interface GridDataModel<T> {
	load(entityGetRequest?: EntityGetRequest): Promise<SuccessResponse>;
	update?(entityUpdateRequest: EntityUpdateRequest): Promise<SuccessResponse>;
	remove?(key: any): Promise<void>;
	onEdit?(data: T): any;
	onReadOnlyView?(data: T): any;
	beforeRowIsInitialized?(data: any): void;
	onAdd?(data: any): Promise<SuccessResponse>;
	onItemAdd?(event: any): void;
	allowRowUpdate?(data: any): boolean;
	allowRowDeletion?(data: any): boolean;
	onDropdownDataColumnSelectionChanged?(data: any): any;
	previewDoc?(data: any): void;
	exportFolder?(sheet: Blob): void;
	dropHandler?(data: any, e: any): void;
	download?(data: T): any;
	archive?(data: T): any;
	customModal?(data: T, event?: any, actionType?: string, showModal?: boolean): any;
	onCheckboxSelection?(data: T[]): any;
	clearGridState?(): any;
}

export interface GridStateModel {
	allowedPageSizes: number[];
	columns: GridColumnStateModel[];
	filterValue: any;
	pageIndex: number;
	pageSize: number;
	searchText: string;
	filterOption: string;
	totalLoadedRecords: number;
	hasActiveFilter: any;
	customGridFilters?: DataGridCustomFilters;
}

export interface GridColumnStateModel {
	dataField: string;
	dataType: string;
	filterValue: any;
	filterValues?: any[];
	name: string;
	selectedFilterOperation: string;
	sortIndex: string;
	sortOrder: string;
	visible: boolean;
	visibleIndex: number;
	width?: number;
}

export interface GridDataExportModel {
	workSheetName: string;
	fileName: string;
	headerTitle?: string | undefined;
	excludeColumn?: string;
}

export interface MenuGroupModel {
	menuGroupGUID?: string;
	menuGroupID?: string;
	description?: string | undefined;
	name?: string | undefined;
	menuItems: MenuItemModel[];
	addedMenuItems: MenuItemModel[];
	deletedMenuItems: MenuItemModel[];
	isSelected: boolean;
	menuStyle: string;
	color?: string;
	imageURL?: string;
}

export interface MenuItemModel {
	menuGUID?: string;
	menuID?: String;
	name?: string;
	description?: string;
	modURL?: string;
	bookmarkedMenuStyle?: string;
	menuStyle?: string;
	menuDescriptionStyle?: string;
	isRecent?: boolean;
	isFavorite?: boolean;
	navigationButtonStyle?: string;
	favoriteOrder?: number;
	updateDate: Date;
	color?: string;
	imageURL?: string;
	parentMenuGUID?: string;
	isNew?: boolean;
	componentName?: string;
	isActive?: boolean;
	isExternalLink?: boolean;
	externalLinkURL?: string;
	isWikiFavorite?: boolean | null;
	queryParams?: string | null;
	internalUserXWikiFavoriteGUID?: string | null;
	isUpdated?: boolean;
	createPermission?: boolean;
}

export interface MenuItemGroupedModel {
	key: string;
	items: MenuItemModel[];
}

export interface HierarchialMenuItemModel extends MenuItemModel {
	children: HierarchialMenuItemModel[];
}

export interface MenuRouteCollection {
	[key: string]: string;
}

export enum BtnState {
	clicked = 'clicked',
	unClicked = 'unClicked',
}

export interface MenuInformationModel {
	menuList: MenuGroupModel[];
	userRecentMenu: MenuGroupModel;
	userFavouriteMenu: MenuGroupModel;
}

export enum SearchComponentType {
	default = 'default',
	adaptive = 'adaptive',
}

export interface LookupSourceModel {
	id?: string;
	name?: string;
	label?: string;
}

export interface WsjPrimeRateModel {
	wsjPrimeRateGUID: string;
	rate: string;
	effectiveDate: Date;
	modifiedBy: string;
	referenceDocumentURL: string;
	modDateTimeGMT: Date;
}

export interface CreditCardBalanceTransferFeeRateModel {
	creditCardBalanceTransferFeeRateGUID: string;
	dollarFee: string;
	percentFee: string;
	effectiveDate: Date;
	modifiedBy: string;
	referenceDocumentURL: string;
	modDateTimeGMT: Date;
}

export interface CreditCardIntroAprRateModel {
	creditCardIntroAprRateGUID: string;
	introRate: string;
	timePeriod: string;
	effectiveDate: Date;
	modifiedBy: string;
	referenceDocumentURL: string;
	modDateTimeGMT: Date;
}

export interface CreditCardIntroAprTimeModel {
	creditCardIntroAprTimeGUID: string;
	timePeriod: string;
}

export interface CreditCardVariableAprRateModel {
	creditCardVariableRateGUID: string;
	min: string;
	max: string;
	effectiveDate: Date;
	modifiedBy: string;
	referenceDocumentURL: string;
	modDateTimeGMT: Date;
}

export interface CDs13MonthPromoRateModel {
	cds13MonthPromoRateGUID: string;
	apr: string;
	apy: string;
	effectiveDate: Date;
	modifiedBy: string;
	referenceDocumentURL: string;
	modDateTimeGMT: Date;
}

export interface CDs17MonthPromoRateModel {
	cds17MonthPromoRateGUID: string;
	apr: string;
	apy: string;
	effectiveDate: Date;
	modifiedBy: string;
	referenceDocumentURL: string;
	modDateTimeGMT: Date;
}
export interface Task {
	actionStepGUID: string;
	parentActionStepGUID: number | string;
	step: string;
	startDate: Date;
	endDate: Date;
	completedPercentage: number;
	color?: string;
}

export interface UserGroup {
	ID: string;
	parentID?: string;
	userId: string;
	internalUserGUID: string;
	label: string;
	isExpanded: boolean;
}

export enum SpinnerState {
	Show = 'Show',
	Hide = 'Hide',
}

export class MonthyReviewDocumentUserMapping {
	userGUID!: string;
	userEmail!: string;
	userId!: string;
	userName!: string;

	constructor(userGUID: string, email: string, userId: string, userName: string) {
		this.userGUID = userGUID;
		this.userEmail = email;
		this.userId = userId;
		this.userName = userName;
	}
}

export class RoleXUserMapping {
	userGUID!: string;
	userEmail!: string;
	userId!: string;
	userName!: string;

	constructor(userGUID: string, email: string, userId: string, userName: string) {
		this.userGUID = userGUID;
		this.userEmail = email;
		this.userId = userId;
		this.userName = userName;
	}
}

export interface CountryInfo {
	countryCode: string;
	countryShortLabel: string;
	countryFullName: string;
}

export interface NotificationReceiveInfoModel {
	notificationGUID?: string;
	fromUserGUID: string;
	fromUserName: string;
	toUserGUID: string;
	type: string;
	messageBody: string;
	mode: string;
	readYN: boolean;
	level: string;
	messageSendTime: string;
	topicName: string;
	category?: string;
	silentYN?: string;
	redirectComponent?: string;
	entityKeyProp?: string;
	entityKeyValue?: string;
	systemCode: string;
}

export interface NotificationMessage {
	fromUserGUID: string;
	toUserGUID: string;
	messageBody: any;
	mode: NotificationType;
	notificationGUID: string;
	readYN: string;
	type: NotificationMessageType;
}

export interface NotificationMessageBody {
	messageType: NotificationMessageType;
	message: any;
}

export interface NotificationGroupMessageProp {
	body: string;
	redirectComponent: string;
	queryParams?: any;
}

export enum NotificationMessageType {
	Chat = 'Chat',
	Notification = 'Notification',
}

export enum NotificationType {
	Single = 'Single',
	Group = 'Group',
}

export interface NotificationInfoModel {
	messageBody: string;
	level: string;
	notificationSendTime: string;
}

export interface NotificationSubscriberPerGroupModel {
	subscriberIdentifier: string;
	subscriberType: UserInfoType;
}
export enum DropdownMode {
	Single = 'single',
	Multiple = 'multiple',
}
export interface Order {
	ID: number;
	OrderNumber: number;
	OrderDate: string;
	SaleAmount: number;
	Terms: string;
	TotalAmount: number;
	CustomerStoreState: string;
	CustomerStoreCity: string;
	Employee: string;
}
export interface customFilterModel {
	aliasName: string;
	columnName: string;
	tableName?: string;
	searchValue?: string;
	searchOption?: string;
}

export interface FilterOption {
	searchColumn: string;
	searchValue: string;
	searchOption: 'contains' | 'notcontains' | 'startswith' | 'endswith' | 'equal' | 'notequal' | '=' | '!=' | '<' | '>' | '<=' | '>=' | '<>';
	searchColumnType: string;
}

export interface ColumnMapping {
	table: string;
	columns: ColumnDef[];
}

export interface ColumnDef {
	column: string;
	alias: string;
	columnType?: ColumnType;
	displayName: string;
}

export enum ColumnType {
	String,
	Numeric,
	Boolean,
	DateTime,
}

export class EmployeeBirthdayItemResponse {
	birthdayList!: EmployeeBirthdayItem[];
	totalRowCount!: number;
}
export class EmployeeAnniversaryItemResponse {
	anniversaryList!: EmployeeAnniversaryItem[];
	totalRowCount!: number;
}
export enum StandAloneGridColumnContainer {
	Default = 'Default',
	Referral = 'Referral',
	ServiceDesk = 'ServiceDesk',
	LoanMaturity = 'LoanMaturity',
}

export enum StorageKey {
	DatGridStorageKey = 'datagrid-state-model',
}
